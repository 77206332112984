import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/@mui/material/GlobalStyles/GlobalStyles.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.ts\",\"import\":\"Public_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"primaryFont\"}");
import(/* webpackMode: "eager" */ "/root/boho-client/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.ts\",\"import\":\"Orelega_One\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"secondaryFont\"}");
import(/* webpackMode: "eager" */ "/root/boho-client/src/app/dashboard-layout-component.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/auth/context/jwt/auth-consumer.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/auth/context/jwt/auth-context.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/auth/context/jwt/auth-provider.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/components/animate/motion-lazy.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/components/progress-bar/progress-bar.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/components/settings/context/settings-context.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/components/settings/context/settings-provider.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/components/settings/drawer/settings-drawer.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/global.css");
import(/* webpackMode: "eager" */ "/root/boho-client/src/locales/i18n.ts");
import(/* webpackMode: "eager" */ "/root/boho-client/src/sections/checkout/context/checkout-context.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/sections/checkout/context/checkout-provider.tsx");
import(/* webpackMode: "eager" */ "/root/boho-client/src/theme/index.tsx")