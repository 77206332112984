import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { RouterLink } from "src/routes/components";

import { useTranslate } from "src/locales/use-locales";

import Logo from "src/components/logo";
import Iconify from "src/components/iconify";
import { useTranslations } from "next-intl";
import { _socials } from "src/utils/socials";
import { paths } from "src/routes/paths";
import { PAYMENT_TYPES } from "src/utils/payment-types";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: "shop-name",
    children: [
      { name: "routes.contact.title", href: paths.contact },
      { name: "routes.all-products.title", href: paths.product.root },

      // { name: 'FAQs', href: '' },
    ],
  },
  {
    headline: "legal",
    children: [
      {
        name: "routes.terms-of-service.title",
        href: paths.legal.termsOfServices,
      },
      { name: "routes.privacy-policy.title", href: paths.legal.privacyPolicy },
      { name: "routes.cookies-policy.title", href: paths.legal.cookiesPolicy },
    ],
  },
  {
    headline: "routes.contact.title",
    children: [
      { name: "shop-email", href: "#" },
      { name: "shop-phone", href: "#" },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  // const pathname = usePathname();

  const year = new Date().getFullYear() || 2022;

  const t = useTranslations();
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 7,
          pb: 4,
          textAlign: { xs: "center", md: "unset" },
        }}
      >
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
        >
          <Grid xs={8} md={3} sx={{ minHeight: 120 }}>
            <Logo height={41} sx={{ marginBottom: '12px', width: 101 }} />

            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                minHeight: 40,
                mx: { xs: "auto", md: "unset" },
              }}
            >
              {t("footer-description")}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  size="medium"
                  key={social.name}
                  sx={{
                    "&:hover": {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                  // href={social.path}
                  onClick={() => {
                    window.open(social.path, "_blank");
                  }}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6} mt={2} sx={{ minHeight: 120 }}>
            <Stack spacing={5} direction={{ xs: "column", md: "row" }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: "center", md: "flex-start" }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {t(list.headline)}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {t(link.name)}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Stack
          display="flex"
          flexDirection={{ sm: "column", md: "row" }}
          justifyContent="space-around"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Stack
            sx={{ mt: { xs: 3, sm: 3, md: 0 } }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {/*<Typography variant="overline" sx={{ textAlign: "center" }}>
              {t("trusted-payment")}
                  </Typography>*/}
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="start"
              justifyContent="center"
              sx={{ mt: 2 }}
            >
              {PAYMENT_TYPES.map((payment) => (
                <Box key={payment.name} sx={{ mx: { xs: 1, sm: 1, md: 2 } }}>
                  {payment.component}
                </Box>
              ))}
            </Stack>
          </Stack>
          <Typography textAlign="right" variant="body2">
            © {year}. {t("all-rights-reserved")}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );

  return mainFooter;
}
