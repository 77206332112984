import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';
import { useTranslations } from 'next-intl';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  animal: icon('ic_animal'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const t = useTranslations();
  const data =  [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          { title: t('routes.all-products.title'), path: paths.product.root,  },


          {
            title: t('routes.categories.title'),
            path: '#',
            children: [
              { title: t('routes.home-page.title'), path: paths.home,},
              { title: t('routes.garden.title'), path: paths.garden,},
              { title: t('routes.lighting.title'), path: paths.lighting},
              { title: t('routes.animals.title'), path: paths.animals },
              { title: t('routes.kitchen.title'), path: paths.kitchen},
              { title: t('routes.gifts.title'), path: paths.gifts},
            ],
          },
          { title: t('routes.christmas-decorations.title'), path: paths.christmasDecorations,  },
        //  { title: t('routes.contact.title'), path: paths.contact,},
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      /*{
        // subheader: 'categories',
        items: [
          { title: t('routes.home-page.title'), path: paths.home,},
          { title: t('routes.garden.title'), path: paths.garden,},
          { title: t('routes.lighting.title'), path: paths.lighting},
          { title: t('routes.animals.title'), path: paths.animals },
          { title: t('routes.kitchen.title'), path: paths.kitchen},
          { title: t('routes.gifts.title'), path: paths.gifts},
        ],
      },*/
    ];

  return data;
}
