'use client';

import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Switch,
    Button,
    FormControlLabel,
    Grid
} from '@mui/material';
import { useTranslations } from 'next-intl';
import { setCookie } from 'cookies-next';
import { ANALYTICS_COOKIE_KEY, COOKIE_CONSENT_COOKIE_KEY, GTM_COOKIE_KEY, removeGoogleAnalytics, removeGoogleTagManager } from './utils';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: 400, sm: '100%', xs: '100%' },
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    zIndex: 9999,
};

const CookiesSettingsModal = ({ open, onClose, onSubmit }: { open: boolean, onClose: () => void, onSubmit: () => void }) => {
    const [mandatoryCookies, setMandatoryCookies] = useState(true);
    const [statisticsCookies, setStatisticsCookies] = useState(false);
    const [marketingCookies, setMarketingCookies] = useState(false);
    const t = useTranslations();
    const handleAcceptAll = () => {
        setCookie(ANALYTICS_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        setCookie(GTM_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        setCookie(COOKIE_CONSENT_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        onSubmit();
    };

    const handleAcceptChosen = () => {
        if (statisticsCookies) {
            // handle remove statistics
            setCookie(ANALYTICS_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        } else {
            setCookie(ANALYTICS_COOKIE_KEY, 'false', { maxAge: 30 * 24 * 60 * 60 });
            removeGoogleAnalytics();
        }
        if (marketingCookies) {
            // handle remove marketing
            setCookie(GTM_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        } else {
            setCookie(GTM_COOKIE_KEY, 'false', { maxAge: 30 * 24 * 60 * 60 });
            removeGoogleTagManager();
        }
        setCookie(COOKIE_CONSENT_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        onSubmit();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('cookies-consent.cookies-settings')}
                </Typography>
                <Typography variant="body2" paragraph>
                    {t('cookies-consent.cookies-settings-description')}
                </Typography>

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={mandatoryCookies}
                                    onChange={() => setMandatoryCookies(!mandatoryCookies)}
                                    disabled
                                />
                            }
                            label={`${t('cookies-consent.mandatory-cookies')} (${t('cookies-consent.always-active')})`}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {t('cookies-consent.mandatory-cookies-description')}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={statisticsCookies}
                                    onChange={() => setStatisticsCookies(!statisticsCookies)}
                                />
                            }
                            label={t('cookies-consent.statistics-cookies')}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {t('cookies-consent.statistics-cookies-description')}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={marketingCookies}
                                    onChange={() => setMarketingCookies(!marketingCookies)}
                                />
                            }
                            label={t('cookies-consent.marketing-cookies')}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {t('cookies-consent.marketing-cookies-description')}
                        </Typography>
                    </Grid>
                </Grid>

                <Box mt={4} display="flex" justifyContent="center">
                    <Button variant="outlined" color="secondary" sx={{ mr: 1 }} onClick={handleAcceptChosen}>
                        {t('cookies-consent.accept-chosen')}
                    </Button>
                    <Button variant="contained" color="secondary" sx={{ ml: 1 }} onClick={handleAcceptAll}>
                        {t('cookies-consent.accept-all')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CookiesSettingsModal;
