'use client';

import merge from 'lodash/merge';
import {
  fr as frFRAdapter,
  vi as viVNAdapter,
  enUS as enUSAdapter,
  zhCN as zhCNAdapter,
  arSA as arSAAdapter,
  pl as plPLAdapter,
} from 'date-fns/locale';

// core
import {
  enUS as enUSCore,
  frFR as frFRCore,
  viVN as viVNCore,
  zhCN as zhCNCore,
  arSA as arSACore,
  plPL as plPLCore,
} from '@mui/material/locale';
// data-grid


// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Polski',
    value: 'pl',
    systemValue: merge(plPLCore),
    adapterLocale: plPLAdapter,
    icon: 'flagpack:pl',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
