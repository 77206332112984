export const _socials = [
    {
      value: 'facebook',
      name: 'FaceBook',
      icon: 'eva:facebook-fill',
      color: '#00a8ff',
      path: 'https://www.facebook.com/people/Sklep-Abli/61558770964592/',
    },
    {
      value: 'instagram',
      name: 'Instagram',
      icon: 'ant-design:instagram-filled',
      color: '#E02D69',
      path: 'https://www.instagram.com/abli_pl/',
    },
    /*{
      value: 'discord',
      name: 'Discord',
      icon: 'ic:baseline-discord',
      color: '#a29bfe',
      path: 'https://www.instagram.com/caitlyn.kerluke',
    },*/
    /* {
      value: 'linkedin',
      name: 'Linkedin',
      icon: 'eva:linkedin-fill',
      color: '#007EBB',
      path: 'https://www.linkedin.com/caitlyn.kerluke',
    },
    {
      value: 'twitter',
      name: 'Twitter',
      icon: 'eva:twitter-fill',
      color: '#00AAEC',
      path: 'https://www.twitter.com/caitlyn.kerluke',
    }, */
  ];

export const _socials_extended = {
  email: 'sklep@abli.pl'
};