import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { memo, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';

import ResultItem from './result-item';
import { useNavData } from '../../dashboard/config-navigation';
import { applyFilter, groupedData, getAllItems } from './utils';
import { useDebounce } from 'src/hooks/use-debounce';
import { useSearchProducts } from 'src/api/product';
import ProductSearch from 'src/sections/product/product-search';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

function Searchbar({onClick}: {onClick?: () => void}) {
  const theme = useTheme();

  const search = useBoolean();

  const handleSearch = useCallback((inputValue: string) => {
    setSearchQuery(inputValue);
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery);
  const { searchResults, searchLoading } = useSearchProducts(debouncedQuery);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);
  return (
    <Box sx={{borderRadius: 1, }}>
      <ProductSearch
        query={debouncedQuery}
        results={searchResults}
        onSearch={handleSearch}
        loading={searchLoading}
        sx={{ width: 'inherit' }}
        hrefItem={(id: string) => paths.product.details(id)}
        onClick={onClick}
      />
    </Box>
  );
}

export default memo(Searchbar);
