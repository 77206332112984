import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import { fToNow } from 'src/utils/format-time';

import Label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import Image from 'src/components/image/image';
import { useTranslations } from 'next-intl';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { m } from 'framer-motion';
import { varHover } from 'src/components/animate';
import { useCheckoutContext } from 'src/sections/checkout/context';

// ----------------------------------------------------------------------

type CartItemProps = {
    cartItem: {
        id: string;
        coverUrl: string;
        price: number;
        name: string;
        quantity: number;
    };
};

export default function CartItem({ cartItem }: CartItemProps) {
    const t = useTranslations();
    const { onDeleteCart } = useCheckoutContext();
    const renderPhoto = (
        <Image
            src={cartItem.coverUrl}
            alt={cartItem.name}
            ratio="1/1"
            sx={{
                minWidth: 112,
                maxWidth: 112,
                height: 112,
                my: 'auto',
                borderRadius: 1.5,
                cursor: 'pointer',
                objectFit: 'cover',
            }}
        />
    );

    return (
        <ListItemButton
            disableRipple
            sx={{
                p: 2.5,
                alignItems: 'flex-start',
                borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
        >

            {renderPhoto}

            <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
                <Typography variant='body2'>
                    {cartItem.name}
                </Typography>
                <Typography variant='body2'>
                    {t('quantity')}:  <strong>{cartItem.quantity}</strong>
                </Typography>
                <Typography variant='body2'>
                  <strong>{cartItem.price} zł</strong>
                </Typography>
            </Stack>
            <IconButton
                    component={m.button}
                    whileTap="tap"
                    whileHover="hover"
                    size='medium'
                    sx={{ width: 34, my: 'auto' }}
                    variants={varHover(1.05)}
                    color={'error'}
                    onClick={() => onDeleteCart(cartItem.id)}
                >
                    <Iconify icon="bi:trash" color="error" />
                </IconButton>
        </ListItemButton>
    );
}
