import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import { useOffSetTop } from "src/hooks/use-off-set-top";
import { useResponsive } from "src/hooks/use-responsive";

import { bgBlur } from "src/theme/css";

import Logo from "src/components/logo";
import SvgColor from "src/components/svg-color";
import { useSettingsContext } from "src/components/settings";

import Searchbar from "../common/searchbar";
import { NAV, HEADER } from "../config-layout";
import SettingsButton from "../common/settings-button";
import AccountPopover from "../common/account-popover";
import ContactsPopover from "../common/contacts-popover";
import LanguagePopover from "../common/language-popover";
import NotificationsPopover from "../common/notifications-popover";
import { useAuthContext } from "src/auth/hooks";
import CartPopover from "../common/cart-popover";
import DarkmodeSwitch from "../common/darkmode-switch";
import { useState } from "react";
import Iconify from "src/components/iconify";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { authenticated, method } = useAuthContext();
  const [isSeachOpened, setSearchOpened] = useState(false);

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === "horizontal";

  const isNavMini = settings.themeLayout === "mini";

  const lgUp = useResponsive("up", "lg");

  const smUp = useResponsive("up", "sm");

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderSearchButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={() => { setSearchOpened(prev => !prev) }}>
        <Iconify icon="eva:search-fill" width={24} />
      </IconButton>

      {/*lgUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>*/}
    </Stack>
  );

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo height={48} width={119} sx={{ marginRight: '8px' }} />}
      {!lgUp && (
        <Logo
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}


      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {smUp && (
          <>
            <DarkmodeSwitch />
            <LanguagePopover />
          </>
        )}

        {/* <NotificationsPopover /> */}

        {/* <SettingsButton /> */}

        {/* <AccountPopover /> */}
        {renderSearchButton}
        <CartPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: 2,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
      {isSeachOpened && <Box
        sx={{
          borderRadius: 1,
          mt: { md: 1, sm: 1, xs: 1 },
          zIndex: 2,
          maxWidth: '720px',
          mx: 'auto',
          ...(!lgUp && {
            width: `100%`,
          }),
          ...(!smUp && {
            position: 'fixed',
            top: HEADER.H_DESKTOP_OFFSET,
          }),
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.shorter,
          }),

          ...(lgUp && {
            width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
            ...(isNavHorizontal && {
              bgcolor: "background.default",
            }),
          }),
        }}>
        <Searchbar onClick={() => { setSearchOpened(false) }} />
      </Box>}
    </AppBar>
  );
}
