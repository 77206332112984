import { sendGAEvent } from "@next/third-parties/google";
import { getCookie } from "cookies-next";
import { GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID } from "src/config-global";

export const COOKIE_CONSENT_COOKIE_KEY = 'abliCookieConsent';
export const ANALYTICS_COOKIE_KEY = 'analyticsConsent';
export const GTM_COOKIE_KEY = 'gtmConsent';

export const hasConsentForAnalytics = () => {
    return getCookie(ANALYTICS_COOKIE_KEY) && getCookie(ANALYTICS_COOKIE_KEY) === 'true';
};
export const hasConsentForGTM = () => {
    return getCookie(GTM_COOKIE_KEY) && getCookie(GTM_COOKIE_KEY) === 'true';
};

export const initializeGoogleAnalytics = () => {
    sendGAEvent('config', {
        id: GOOGLE_ANALYTICS_ID,
        cookie_flags: 'SameSite=None;Secure'
    });
}

export const removeGoogleAnalytics = () => {
    window[`ga-disable-${GOOGLE_ANALYTICS_ID}`] = true;
}

export const initializeGoogleTagManager = () => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID!}`;
    script.async = true;
    document.head.appendChild(script);
}

export const removeGoogleTagManager = () => {
    const existingScript = document.querySelector('script[src*="googletagmanager"]');
    if (existingScript) {
        existingScript.remove();
    }
}