import { m, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
import { _notifications } from "src/_mock";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { varHover } from "src/components/animate";
import CartItem from "./cart-item";
import { useCheckoutContext } from "src/sections/checkout/context";
import { paths } from "src/routes/paths";
import { useTranslations } from "next-intl";
import { Link } from "@mui/material";
import { RouterLink } from "src/routes/components";
import EmptyContent from "src/components/empty-content/empty-content";
import { fCurrency } from "src/utils/format-number";

// ----------------------------------------------------------------------

export default function CartPopover() {
  const drawer = useBoolean();
  const t = useTranslations();
  const smUp = useResponsive("up", "sm");
  const checkout = useCheckoutContext();
  const [previousTotalItems, setPreviousTotalItems] = useState(checkout.totalItems);
  const animationControls = useAnimation();
  
  useEffect(() => {
    if (checkout.totalItems > previousTotalItems) {
      animationControls.start({
        scale: [1, 1.4, 1],
        transition: {
          duration: 0.8,
          ease: "easeInOut",
        },
      });
    }
    setPreviousTotalItems(checkout.totalItems);
  }, [checkout.totalItems, previousTotalItems, animationControls]);

  const empty = !checkout.items.length;
  const cartItems = checkout.items;

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }} textAlign="center">
        {t("shopping-cart")}
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {cartItems.map((cartItem) => (
          <CartItem key={cartItem.id} cartItem={cartItem} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
              // component={m.button}
        // whileTap="tap"
        // whileHover="hover"
        //  component={RouterLink}
        // href={paths.product.checkout}
        //   variants={varHover(1.05)}
        // color={drawer.value ? "primary" : "default"}
      onClick={drawer.onTrue}>
        <Badge
          showZero
          badgeContent={checkout.totalItems}
          color="error"
          max={99}
          component={m.div}
          animate={animationControls}
        >
          <Iconify icon="solar:cart-3-bold" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        {empty ? (
          <EmptyContent
            title={t("cart-is-empty")}
            description={t("look-like-you-do-not-have-any-products-in-cart")}
            imgUrl="/assets/icons/empty/ic_cart.svg"
            sx={{ pt: 5, pb: 10 }}
          />
        ) : (
          renderList
        )}
        
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="space-between" px={3}>
          <Typography variant="subtitle1">{t("total")}</Typography>
          <Box sx={{ textAlign: "right" }}>
            <Typography variant="subtitle1">
              {fCurrency(checkout.total)} zł
            </Typography>
          </Box>
        </Stack>
        
        <Divider sx={{ my: 2 }} />
        <Box sx={{ p: 1, mb: 1 }}>
          <Link
            component={RouterLink}
            href={paths.product.checkout}
            onClick={drawer.onFalse}
          >
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
            >
              {t("go-to-checkout")}
            </Button>
          </Link>
        </Box>
      </Drawer>
    </>
  );
}
