import Iconify from "src/components/iconify";

export const PAYMENT_TYPES = [
    {
        name: 'Visa',
        icon: '',
        component: <Iconify icon={"logos:visa"} width={36} />,
    },
    {
        name: 'Mastercard',
        icon: '',
        component: <Iconify icon={"logos:mastercard"} width={36} />,
    },
    {
        name: "Blik",
        icon: "/assets/icons/blik.svg",
        component: <img src={"/assets/icons/blik.svg"} height={20} width={42.27}/>,
    },
    {
        name: "Przelewy24",
        icon: "/assets/icons/p24.png",
        component: <img src={"/assets/icons/p24.png"} height={23} width={65.7} />,
    },
    {
        name: 'PayPal',
        icon: '',
        component: <Iconify icon={"logos:paypal"} width={23} />,
    },
    /* {
        name: 'ApplePay',
        icon: '',
        component: <Iconify icon={"logos:apple-pay"} width={42} />,
    },
    {
        name: 'GooglePay',
        icon: '',
        component: <Iconify icon={"logos:google-pay"} width={42} />,
    } */
]