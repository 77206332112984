'use client';

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Link, alpha, Stack } from '@mui/material';
import { getCookie, setCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';
import CookiesSettingsModal from './cookies-settings-modal';
import { paths } from 'src/routes/paths';
import { ANALYTICS_COOKIE_KEY, COOKIE_CONSENT_COOKIE_KEY, GTM_COOKIE_KEY, hasConsentForAnalytics, hasConsentForGTM, removeGoogleAnalytics, removeGoogleTagManager } from './utils';
import { useResponsive } from 'src/hooks/use-responsive';

const CookiesConsent = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [cookiesSettings, setCookiesSettings] = useState(false);
    const smUp = useResponsive('up', 'sm');
    const t = useTranslations();

    useEffect(() => {
        if (!getCookie(COOKIE_CONSENT_COOKIE_KEY)) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
            if (!hasConsentForAnalytics()) {
                removeGoogleAnalytics();
            }
            if (!hasConsentForGTM()) {
                removeGoogleTagManager();
            }
        }
    }, []);

    const handleAccept = () => {
        setCookie(GTM_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60, sameSite: 'none', secure: false, httpOnly: true });
        setCookie(ANALYTICS_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 });
        setCookie(COOKIE_CONSENT_COOKIE_KEY, 'true', { maxAge: 30 * 24 * 60 * 60 }); // Cookie expires in 30 days
        setIsVisible(false);

    };

    const handleReject = () => {
        setCookie(GTM_COOKIE_KEY, 'false', { maxAge: 30 * 24 * 60 * 60 });
        setCookie(ANALYTICS_COOKIE_KEY, 'false', { maxAge: 30 * 24 * 60 * 60 });
        setCookie(COOKIE_CONSENT_COOKIE_KEY, 'false', { maxAge: 30 * 24 * 60 * 60 });
        setIsVisible(false);
    };

    const handleSettings = () => {
        // setCookie(COOKIE_CONSENT_COOKIE_KEY, 'false', { maxAge: 30 * 24 * 60 * 60 });
        setIsVisible(false);
        setCookiesSettings(true);
    };

    const onSettingsClose = () => {
        setCookiesSettings(false);
        setIsVisible(true);
    }

    const onSettingsSubmit = () => {
        setCookiesSettings(false);
        setIsVisible(false);
    }
    return (
        <>
            <CookiesSettingsModal open={cookiesSettings} onClose={onSettingsClose} onSubmit={onSettingsSubmit} />
            {isVisible ? <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    maxWidth: '910px',
                    bgcolor: theme => `${alpha(theme.palette.background.paper, 0.95)}`,
                    p: 2,
                    zIndex: 9997,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: { xs: 2, sm: 2, md: 12 },
                    boxShadow: 8,
                }}
            >
                <Box>
                    <Typography variant="h6" sx={{ my: 2 }}>
                        {t('cookies-consent.title')}
                    </Typography>
                    <Typography variant="body2">
                        {!smUp ? (
                            <>
                                {t('cookies-consent.content')}{' '}
                                <Link href={paths.legal.cookiesPolicy} color="inherit" underline="always">
                                    {t('cookies-consent.cookie-policy')}
                                </Link>.
                            </>
                        ) : (
                            <>
                                {t('cookies-consent.content-short')}{' '}
                                <Link href={paths.legal.cookiesPolicy} color="inherit" underline="always">
                                    {t('cookies-consent.cookie-policy')}
                                </Link>.
                            </>
                        )}
                    </Typography>
                    <Stack spacing={{ sm: 1, xs: 1, md: 2 }} sx={{ my: 2, display: 'flex', flexDirection: { md: 'row', sm: 'column', xs: 'column' }, justifyContent: 'center', alignItems: 'center' }}>
                        <Button fullWidth variant="outlined" color="secondary" size='medium' onClick={handleReject} sx={{ px: 4 }}>
                            {t('cookies-consent.decline')}
                        </Button>
                        <Button fullWidth variant="outlined" color="secondary" size='medium' onClick={handleSettings} sx={{ px: 4, }}>
                            {t('cookies-consent.settings')}
                        </Button>
                        <Button fullWidth variant="contained" color="secondary" size='medium' onClick={handleAccept} sx={{ px: 4 }}>
                            {t('cookies-consent.accept')}
                        </Button>
                    </Stack>
                </Box>
            </Box> : null}
        </>
    );
};

export default CookiesConsent;