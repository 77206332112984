'use client';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthGuard } from 'src/auth/guard';
import CookiesConsent from 'src/components/cookies-consent';
import { STRIPE_KEY } from 'src/config-global';
import DashboardLayout from 'src/layouts/dashboard';
import ThemeProvider from 'src/theme';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const stripePromise = loadStripe(STRIPE_KEY!);

export default function DashboardLayoutComponent({ children }: Props) {
  return (
    <ThemeProvider>
      <AuthGuard>
        <Elements stripe={stripePromise}>
          <CookiesConsent />
          <DashboardLayout>{children}</DashboardLayout>
        </Elements>
      </AuthGuard>
    </ThemeProvider>
  );
}
